<template>
    <v-dialog v-model="dialog" @click:outside="$emit('input', false)">
        <v-card>
            <v-toolbar dark color="primary">
                <v-btn icon dark @click="$emit('input', false)">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                    ASIN:
                    <a
                        v-if="item"
                        target="_blank"
                        :href="'https://amazon.com/dp/' + item.asin"
                        >{{ item.asin }}</a
                    >
                </v-toolbar-title>
            </v-toolbar>
            <div v-if="item" class="d-flex pa-4">
                <table style="width: 100%">
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex justify-center align-center">
                                    <v-img
                                        max-width="108"
                                        min-width="108"
                                        width="108"
                                        :src="item.image"
                                    ></v-img>
                                </div>
                            </td>
                            <td style="vertical-align: top">
                                <table style="width: 50%">
                                    <tbody>
                                        <tr>
                                            <td>ASIN:</td>
                                            <td>{{ item.asin }}</td>
                                        </tr>
                                        <tr>
                                            <td>SKU:</td>
                                            <td>{{ item.sku }}</td>
                                        </tr>
                                        <tr>
                                            <td>FNSKU:</td>
                                            <td>{{ item.fnSku }}</td>
                                        </tr>
                                        <tr>
                                            <td>Created at:</td>
                                            <td>{{ selectedCreatedAt }}</td>
                                        </tr>
                                        <tr>
                                            <td>PIC:</td>
                                            <td>{{ selectedPic }}</td>
                                        </tr>
                                        <tr>
                                            <td>Supplier:</td>
                                            <td>{{ selectedSupplier }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td style="vertical-align: top">
                                <table style="width: 50%">
                                    <tbody>
                                        <tr>
                                            <td>Label:</td>
                                            <td>
                                                {{ item.label }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Tag:</td>
                                            <td>
                                                <v-chip
                                                    v-if="item.is_low_price"
                                                >
                                                    {{ "LowPrice" }}
                                                </v-chip>
                                                <v-chip v-if="item.is_neg_gp">
                                                    {{ "NegGP" }}
                                                </v-chip>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex pa-4">
                <calendar-filter
                    class="flex-grow-1"
                    :skip-url="true"
                    :custom-ranges="dateRanges"
                    @update="(val) => applyAsinReportFilter(val)"
                ></calendar-filter>
            </div>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-card-text v-if="!report">
                            Loading...
                            <v-progress-linear
                                indeterminate
                            ></v-progress-linear>
                        </v-card-text>

                        <highcharts
                            id="sale-chart"
                            key="sale-chart"
                            v-if="report"
                            :options="chartOptions"
                        ></highcharts>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <highcharts
                            id="sold-chart"
                            key="sold-chart"
                            v-if="soldReport"
                            :options="soldChartOptions"
                        ></highcharts>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item>
                    <v-list-item-content>
                        <CogCatalogItemTransactionSimpleList
                            :cogCatalogItemId="item.cog_catalog_item_id"
                            :currentQuantity="item.currentQuantity"
                            :hide-actions="true"
                        ></CogCatalogItemTransactionSimpleList>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<script>
import "vue2-ez-daterange-picker/dist/vue2-daterange-picker.css";
import { sourceTz, tz } from "@/constants/Time";
import moment from "moment-timezone";
import { Chart } from "highcharts-vue";
import CalendarFilter from "@/components/CalendarFilter.vue";
import dateRanges from "@/constants/DateRanges";
import Highcharts from "highcharts";

export default {
    name: "AsinProfile",
    components: {
        CalendarFilter,
        highcharts: Chart,
    },
    props: ["marketplaceId", "customRanges", "selected", "dialog"],
    model: {
        prop: "dialog",
        event: "input",
    },
    computed: {
        selectedCreatedAt() {
            return this.item.created_at
                ? this.item.created_at.split("T")[0]
                : "";
        },
        selectedPic() {
            return this.item.pics
                ? this.item.pics.map((user) => user.name).join(", ")
                : "";
        },
        selectedSupplier() {
            return this.item.suppliers
                ? this.item.suppliers
                      .map((supplier) => supplier.name)
                      .join(", ")
                : "";
        },
    },
    data() {
        return {
            report: false,
            soldReport: false,
            cachedReport: {},
            item: { ...this.selected },
            dateRanges,
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        title: {
                            text: "Sales Rank",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        labels: {
                            format: "{value}",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                    },
                    {
                        title: {
                            text: "Price",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        labels: {
                            format: "{value}$",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    {
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[2],
                            },
                        },
                        title: {
                            text: "Quantity",
                            style: {
                                color: Highcharts.getOptions().colors[2],
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Sales Rank",
                        type: "spline",
                        yAxis: 0,
                        data: [],
                        tooltip: {
                            valueSuffix: " ",
                        },
                    },
                    {
                        name: "Pricing",
                        type: "spline",
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: "$",
                        },
                    },
                    {
                        name: "Quantity",
                        type: "spline",
                        yAxis: 2,
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                ],
            },
            soldChartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        // Primary yAxis
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        title: {
                            text: "Items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    // {
                    //     // Secondary yAxis
                    //     title: {
                    //         text: "Rev",
                    //         style: {
                    //             color: Highcharts.getOptions().colors[0],
                    //         },
                    //     },
                    //     labels: {
                    //         format: "{value}$",
                    //         style: {
                    //             color: Highcharts.getOptions().colors[0],
                    //         },
                    //     },
                    //     opposite: true,
                    // },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Items",
                        type: "spline",
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                    // {
                    //     name: "Rev",
                    //     type: "column",
                    //     yAxis: 1,
                    //     data: [],
                    //     tooltip: {
                    //         valueSuffix: "$",
                    //     },
                    // },
                ],
            },
        };
    },
    watch: {
        dialog(newVal) {
            if (!newVal) {
                return;
            }

            this.item = { ...this.selected };

            this.applyAsinReportFilter({
                startDate: moment()
                    .startOf("month")
                    .tz(sourceTz)
                    .startOf("day"),
                endDate: moment().endOf("month").tz(sourceTz).startOf("day"),
            });
        },
    },
    methods: {
        async applyAsinReportFilter(value) {
            let filter = {
                asin: this.item.asin,
                start: value.startDate.clone().format("YYYY-MM-DD"),
                end: value.endDate.clone().format("YYYY-MM-DD"),
            };

            if (this.marketplaceId) {
                filter.marketplace_id = this.marketplaceId;
            }

            this.report = false;
            this.soldReport = false;
            if (
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${this.marketplaceId}`
                ]
            ) {
                this.report =
                    this.cachedReport[
                        `${filter.asin}_${filter.start}_${filter.end}_${this.marketplaceId}`
                    ];
                this.soldReport = { ...this.report.sold };
                this.item = {
                    ...this.report.detail,
                    image: this.report.detail.small_image
                        ? this.report.detail.small_image.URL
                        : this.item.image,
                };
            } else {
                const response = await this.$store.dispatch(
                    "SaleInventoryReport/getList",
                    {
                        filter,
                    }
                );
                this.report = response.data;
                this.soldReport = { ...this.report.sold };
                this.item = {
                    ...this.report.detail,
                    image: this.report.detail.small_image
                        ? this.report.detail.small_image.URL
                        : this.item.image,
                };
                this.cachedReport[
                    `${filter.asin}_${filter.start}_${filter.end}_${this.marketplaceId}`
                ] = { ...response.data };
            }
            this.chartOptions.xAxis[0].categories =
                this.report.total_quantity.map((i) => {
                    if (i.year) {
                        return moment()
                            .year(i.year)
                            .month(i.month - 1)
                            .tz(tz)
                            .format("MMM YYYY");
                    }
                    return moment(i.at_date).tz(tz).format("ll");
                });
            this.chartOptions.series[0].data =
                this.report.total_sales_ranking.map((i) => 1 * i.count);
            this.chartOptions.series[1].data =
                this.report.total_listing_price.map((i) => 1 * i.count);
            this.chartOptions.series[2].data = this.report.total_quantity.map(
                (i) => 1 * i.count
            );

            this.soldChartOptions.xAxis[0].categories =
                this.report.sold.total_quantity.map((i) => {
                    if (i.year) {
                        return moment()
                            .year(i.year)
                            .month(i.month - 1)
                            .tz(tz)
                            .format("MMM YYYY");
                    }
                    return moment(i.at_date).tz(tz).format("ll");
                });

            this.soldChartOptions.series[0].data =
                this.report.sold.total_quantity.map((i) => 1 * i.count);
            // this.soldChartOptions.series[1].data =
            //     this.report.sold.total_amount.map((i) => 1 * i.count);
        },
    },
};
</script>
