import moment from "moment-timezone";

let yesterdayStart = moment().subtract(1, "days").startOf("day").toDate();
let yesterdayEnd = moment().subtract(1, "days").endOf("day").toDate();
let thisMonthStart = moment().startOf("month").startOf("day").toDate();
let thisMonthEnd = moment().endOf("month").startOf("day").toDate();
let lastMonthStart = moment()
    .subtract(1, "month")
    .startOf("month")
    .startOf("day")
    .toDate();
let lastMonthEnd = moment()
    .subtract(1, "month")
    .endOf("month")
    .startOf("day")
    .toDate();
let thisYearStart = moment().startOf("year").startOf("day").toDate();
let thisYearEnd = moment().endOf("year").startOf("day").toDate();

const currentQuarter = moment().quarter();
let startOfQuarter = moment()
    .quarter(currentQuarter)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfQuarter = moment()
    .quarter(currentQuarter)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastYear = moment()
    .subtract(1, "year")
    .startOf("year")
    .startOf("day")
    .toDate();
let endOfLastYear = moment()
    .subtract(1, "year")
    .endOf("year")
    .endOf("day")
    .toDate();

export const DateRanges = {
    "This month": [thisMonthStart, thisMonthEnd],
    "Last 30 days": [moment(yesterdayStart).subtract(30, "day").toDate(), yesterdayEnd],
    "Last 60 days": [moment(yesterdayStart).subtract(60, "day").toDate(), yesterdayEnd],
    "Last 90 days": [moment(yesterdayStart).subtract(90, "day").toDate(), yesterdayEnd],
    "Last month": [lastMonthStart, lastMonthEnd],
    "This quarter": [startOfQuarter, endOfQuarter],
    "Last quarter": [startOfLastQuarter, endOfLastQuarter],
    "This year": [thisYearStart, thisYearEnd],
    "Last year": [startOfLastYear, endOfLastYear],
};

export default DateRanges;
