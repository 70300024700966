var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[_c('financial-transaction-adjust-cog',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAdjustCog),expression:"showAdjustCog"}],attrs:{"mwsConfigurationOptions":_vm.mwsConfigurationOptions},on:{"saved":_vm.onSaved}})],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"financial-transaction-list",attrs:{"resource":_vm.resource.name,"filters":_vm.filters,"items-per-page":_vm.perPage,"filter":_vm.filter,"sort-by":_vm.sort.by,"sort-desc":_vm.sort.desc,"disable-create":"","disable-export":""},on:{"update:filter":function($event){_vm.filter=$event},"update:sortBy":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sort, "by", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.sort, "desc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.sort, "desc", $event)}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('va-action-button',{attrs:{"label":"Adjust COGs","icon":"mdi-upload","text":""},on:{"click":function($event){_vm.asideTitle = 'Adjust COGs';
                        _vm.showAdjustCog = true;}}})]},proxy:true},{key:"filter.date",fn:function(props){return [_c('calendar-filter',_vm._b({on:{"update":function (val) { return _vm.update(val); }}},'calendar-filter',props,false))]}}])},[_c('va-data-table',{ref:"table",staticClass:"main-table",attrs:{"fields":_vm.fields,"disable-actions":"","disable-select":"","show-expand":""},scopedSlots:_vm._u([{key:"field.date",fn:function(ref){
                        var value = ref.value;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(value,"timezone", _vm.sourceTz, "LLL")))])]}},{key:"field.NET",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTotalNet(item).toFixed(2)))])]}},{key:"expanded-item",fn:function(ref){
                        var item = ref.item;
return [(item.entity_type !== 'OperationFee')?[(
                                item.entity_type !== 'AdjustmentEvent' &&
                                item.children &&
                                item.children.length &&
                                item.children[0].SellerSKU
                            )?_c('v-data-table',{staticClass:"item-headers",attrs:{"hide-default-header":"","hide-default-footer":"","show-expand":"","items-per-page":100,"headers":_vm.item_headers,"items":item.children},scopedSlots:_vm._u([{key:"item.NET",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s((item.amount - (item.cog || 0)).toFixed( 2 ))+" ")]}},{key:"expanded-item",fn:function(ref){
                            var item = ref.item;
return [(item.children.length)?_c('td',{attrs:{"colspan":"4"}},[_c('v-data-table',{staticClass:"transition-headers",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":100,"headers":_vm.transition_headers,"items":item.children}})],1):_vm._e()]}}],null,true)}):(
                                item.entity_type !== 'AdjustmentEvent' &&
                                item.children &&
                                item.children.length
                            )?_c('v-data-table',{staticClass:"transition-headers",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":100,"headers":_vm.transition_headers,"items":item.children}}):(item.transaction_type)?_c('v-data-table',{staticClass:"transition-headers",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":100,"headers":_vm.transition_headers,"items":[item]}}):_vm._e()]:_vm._e(),(item.entity_type === 'OperationFee')?[_c('v-data-table',{staticClass:"operation-fee-headers",attrs:{"hide-default-header":"","hide-default-footer":"","items-per-page":100,"headers":_vm.operation_fee_headers,"items":item.children}})]:_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }