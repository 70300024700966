var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('va-aside-layout',{attrs:{"title":_vm.asideTitle}},[_c('cog-catalog-item-import',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImport),expression:"showImport"}],attrs:{"mwsConfigurationOptions":_vm.mwsConfigurationOptions,"filter":_vm.filter},on:{"saved":_vm.onSaved}}),_c('cog-catalog-item-bulk',{directives:[{name:"show",rawName:"v-show",value:(_vm.showBulk),expression:"showBulk"}],attrs:{"mwsConfigurationOptions":_vm.mwsConfigurationOptions,"filter":_vm.filter},on:{"saved":_vm.onSaved}}),_c('cog-catalog-item-edit',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEdit),expression:"showEdit"}],attrs:{"id":_vm.id,"item":_vm.item},on:{"saved":_vm.onSaved}})],1),_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"cog-catalog-item-list",attrs:{"filters":_vm.filters,"filter":_vm.filter,"disable-export":"","disable-create":"","items-per-page":100,"items-per-page-options":[100, 200, 300, 500]},on:{"update:filter":function($event){_vm.filter=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"actions-wrapper"},[_c('va-action-button',{attrs:{"label":"Import COGs","icon":"mdi-upload","text":""},on:{"click":function($event){_vm.asideTitle = 'Import COGs';
                            _vm.showImport = true;
                            _vm.showEdit = false;
                            _vm.showBulk = false;}}}),_c('va-action-button',{attrs:{"label":"Export All","icon":"mdi-download","text":"","loading":_vm.isGenerating},on:{"click":_vm.exportAll}}),_c('va-action-button',{attrs:{"label":"Bulk update COGs","icon":"mdi-upload","text":""},on:{"click":function($event){_vm.asideTitle = 'Bulk Update Info';
                            _vm.showBulk = true;
                            _vm.showImport = false;
                            _vm.showEdit = false;}}}),_c('a',{ref:"fileLinkElement",staticClass:"d-none",attrs:{"href":_vm.fileLink,"download":_vm.exportFileName}},[_vm._v("Download exported file.")])],1)]},proxy:true}])},[_c('va-data-table',{ref:"table",staticClass:"main-table",attrs:{"fields":_vm.fields,"disable-clone":"","disable-select":"","disable-delete":_vm.disableDelete,"disable-show":"","disable-edit-redirect":""},on:{"item-action":_vm.onAction},scopedSlots:_vm._u([{key:"field.AttributeSets[0]",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [(value && value.SmallImage)?_c('img',{attrs:{"width":"75","alt":value.SmallImage.URL,"src":_vm.getImage(item, value.SmallImage.URL)}}):_vm._e()]}},{key:"field.ASIN",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openAsinReport(item)}}},[_vm._v(_vm._s(value))])]}},{key:"field.cog",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openTransactionDialog(item)}}},[_vm._v(_vm._s(value))])]}},{key:"field.listing_price",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.currentQuantity > 0 ? value ? value : "None" : "-"))])]}},{key:"field.referral_fee",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.currentQuantity > 0 ? value ? value : "None" : "-"))])]}},{key:"field.fba_fee",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.currentQuantity > 0 ? value ? value : "None" : "-"))])]}},{key:"field.gp",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('span',[(item.currentQuantity > 0)?_c('strong',[_vm._v(" "+_vm._s(value ? value : "None")+" "+_vm._s(value && item.listing_price ? " (" + ( (value * 100) / item.listing_price ).toFixed(2) + "%)" : "")+" ")]):_c('strong',[_vm._v("-")])])]}},{key:"field.barcode_file",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [(value)?_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.downloadBarcode(item)}}},[_vm._v("Download")]):_c('span',[_vm._v("No file")])]}},{key:"field.label",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [(value)?_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),_vm._v(" - ("+_vm._s(parseInt(item.sum_sale_30_day, 10))+") ")]}},{key:"field.tags",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [(item.is_low_price)?_c('v-chip',[_vm._v(" "+_vm._s("LowPrice")+" ")]):_vm._e(),(item.is_neg_gp)?_c('v-chip',[_vm._v(" "+_vm._s("NegGP")+" ")]):_vm._e()]}},{key:"field.created_at",fn:function(ref){
                            var value = ref.value;
                            var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getUploadStatusColor(item)}},[_vm._v(" "+_vm._s(_vm._f("moment")(value,"timezone", _vm.sourceTz, "LLL"))+" ")]),_vm._v(" - "+_vm._s(item.asin_age)+" ")]}}],null,true)})],1),_c('v-dialog',{on:{"click:outside":_vm.closeTransactionDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeTransactionDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.item)?_c('v-toolbar-title',[_vm._v(" SKU: "),_c('a',{attrs:{"target":"_blank","href":'https://amazon.com/dp/' + _vm.item.asin}},[_vm._v(_vm._s(_vm.item.sku))])]):_vm._e()],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(_vm.dialog)?_c('CogCatalogItemTransactionSimpleForm',{attrs:{"cogCatalogItemId":_vm.item.id},on:{"afterSave":_vm.afterChangeCogCatalogItemTransaction}}):_vm._e(),_c('v-divider'),(_vm.dialog && _vm.item)?_c('CogCatalogItemTransactionSimpleList',{attrs:{"cogCatalogItemId":_vm.item.id,"currentQuantity":_vm.item.currentQuantity}}):_vm._e()],1)],1)],1)],1)],1),_c('AsinProfile',{attrs:{"selected":_vm.selected,"custom-ranges":_vm.dateRanges,"marketplace-id":this.filter ? this.filter.marketplace_id : null},model:{value:(_vm.asinDialog),callback:function ($$v) {_vm.asinDialog=$$v},expression:"asinDialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }