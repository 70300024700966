<template>
    <date-range-picker
        ref="picker"
        :opens="'left'"
        :timePicker="false"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="true"
        v-model="dateRange"
        :ranges="ranges"
        @update="(val) => update(val)"
        :locale-data="{
            firstDay: 1,
        }"
    >
        <template v-slot:input="picker" style="min-width: 350px">
            <label
                v-if="!picker.startDate || !picker.startDate"
                class="v-label theme--light"
            >
                Date Range
            </label>
            <template v-else>
                {{ displayValue }}
            </template>
        </template>
    </date-range-picker>
</template>

<script>
import "vue2-ez-daterange-picker/dist/vue2-daterange-picker.css";
import { sourceTz, tz } from "@/constants/Time";
import DateRanges from "@/constants/DateRanges";
import moment from "moment-timezone";
import DateRangePicker from "vue2-ez-daterange-picker";

export default {
    name: "CalendarFilter",
    components: { DateRangePicker },
    props: ["customRanges", "skipUrl"],
    async mounted() {},
    beforeMount() {
        const { filter } = this.$route.query;
        if (!filter || this.skipUrl) {
            this.dateRange.startDate = moment()
                .startOf("month")
                .tz(sourceTz)
                .startOf("day");
            this.dateRange.endDate = moment()
                .endOf("month")
                .tz(sourceTz)
                .startOf("day");
            this.displayValue =
                this.dateRange.startDate.format("L") +
                " - " +
                this.dateRange.endDate.format("L");
            return;
        }
        const currentFilter = JSON.parse(filter);
        if (!currentFilter["date_between"]) {
            return;
        }
        const [start, end] = currentFilter["date_between"].split(",");
        this.dateRange.startDate = moment(start).tz(tz);
        this.dateRange.endDate = moment(end).tz(tz);
        this.displayValue =
            this.dateRange.startDate.format("L") +
            " - " +
            this.dateRange.endDate.format("L");
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            dateRange: {},
            displayValue: "",
            ranges: this.customRanges || {
                Today: DateRanges.Today,
                Yesterday: DateRanges.Yesterday,
                "This month": DateRanges["This month"],
                "Last month": DateRanges["Last month"],
                "This year": DateRanges["This year"],
            },
        };
    },
    methods: {
        update(value) {
            const { startDate, endDate, isClickRange } = value;
            let start, end;
            if (
                isClickRange &&
                startDate.toDateString() === endDate.toDateString()
            ) {
                start = moment(endDate).tz(sourceTz);
                end = moment(endDate).tz(sourceTz);
            } else {
                start = moment(startDate).tz(tz);
                end = moment(endDate).tz(tz);
            }

            this.displayValue = start.format("L") + " - " + end.format("L");

            this.$emit("update", {
                startDate: start.clone(),
                endDate: end.clone(),
                formattedStartDate: start.clone().format("YYYY-MM-DD 00:00:00"),
                formattedEndDate: end.clone().format("YYYY-MM-DD 23:59:59"),
            });
        },
    },
};
</script>
