import moment from "moment-timezone";

let today = moment().startOf("day").toDate();
let todayEnd = moment().endOf("day").toDate();
let yesterdayStart = moment().subtract(1, "days").startOf("day").toDate();
let yesterdayEnd = moment().subtract(1, "days").endOf("day").toDate();
let thisMonthStart = moment().startOf("month").startOf("day").toDate();
let thisMonthEnd = moment().endOf("month").startOf("day").toDate();
let lastMonthStart = moment()
    .subtract(1, "month")
    .startOf("month")
    .startOf("day")
    .toDate();
let lastMonthEnd = moment()
    .subtract(1, "month")
    .endOf("month")
    .startOf("day")
    .toDate();
let thisYearStart = moment().startOf("year").startOf("day").toDate();
let thisYearEnd = moment().endOf("year").startOf("day").toDate();

let startOfWeek = moment().startOf("week").startOf("day").toDate();
let endOfWeek = moment().endOf("week").endOf("day").toDate();
let startOfLastWeek = moment()
    .subtract(1, "week")
    .startOf("week")
    .startOf("day")
    .toDate();
let endOfLastWeek = moment()
    .subtract(1, "week")
    .endOf("week")
    .endOf("day")
    .toDate();
const currentQuarter = moment().quarter();
let startOfQuarter = moment()
    .quarter(currentQuarter)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfQuarter = moment()
    .quarter(currentQuarter)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .startOf("quarter")
    .startOf("day")
    .toDate();
let endOfLastQuarter = moment()
    .quarter(currentQuarter - 1)
    .endOf("quarter")
    .endOf("day")
    .subtract(1, "day")
    .toDate();
let startOfLastYear = moment()
    .subtract(1, "year")
    .startOf("year")
    .startOf("day")
    .toDate();
let endOfLastYear = moment()
    .subtract(1, "year")
    .endOf("year")
    .endOf("day")
    .toDate();

export const DateRanges = {
    Today: [today, todayEnd],
    Yesterday: [yesterdayStart, yesterdayEnd],
    "This week": [startOfWeek, endOfWeek],
    "Last week": [startOfLastWeek, endOfLastWeek],
    "This month": [thisMonthStart, thisMonthEnd],
    "Last month": [lastMonthStart, lastMonthEnd],
    "This quarter": [startOfQuarter, endOfQuarter],
    "Last quarter": [startOfLastQuarter, endOfLastQuarter],
    "This year": [thisYearStart, thisYearEnd],
    "Last year": [startOfLastYear, endOfLastYear],
};

export default DateRanges;
