<template>
    <div>
        <v-card-text v-if="isLoading">
            Loading...
            <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
        <v-data-table
            ref="table"
            hide-default-footer
            class="transaction-items-table"
            :headers="headers"
            :items="items"
        >
            <template v-slot:item.created_at="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    v-if="item.id === fistItemId"
                    text
                    :loading="isLoading"
                    @click="deleteAndRevertItem(item)"
                >
                    <v-icon small>mdi-delete</v-icon>
                    Delete and Revert
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ["cogCatalogItemId", "currentQuantity", "hideActions"],
    data() {
        let headers = [
            {
                text: "Created At",
                value: "created_at",
                sortable: true,
            },
            {
                text: "Quantity",
                value: "quantity",
                sortable: true,
            },
            {
                text: "COGs",
                value: "cog",
                sortable: true,
            },
            {
                text: "Shipping code",
                value: "shipment_code",
                sortable: true,
            },
            { text: "Actions", value: "actions", sortable: false },
        ];

        if (this.hideActions) {
            headers = headers.filter((header) => header.value !== "actions");
        }

        return {
            isLoading: false,
            fistItemId: false,
            items: [],
            headers,
        };
    },
    watch: {
        cogCatalogItemId: {
            immediate: true,
            handler(newVal) {
                if (!newVal) {
                    return;
                }
                this.getItems();
                this.getSummary();
            },
        },
    },
    async mounted() {},
    methods: {
        async deleteAndRevertItem(item) {
            this.isLoading = true;
            await this.$store.dispatch("CogCatalogItemTransaction/delete", {
                id: item.id,
            });
            this.$root.$emit("afterDeleteAndRevert", item);
            await this.getItems();
        },
        async getItems() {
            const filter = {
                cog_catalog_item_id: this.cogCatalogItemId,
            };
            const sort = [
                {
                    by: ["created_at"],
                    desc: [true],
                },
            ];
            this.isLoading = true;
            this.items = [];
            const response = await this.$store.dispatch(
                "CogCatalogItemTransaction/getList",
                {
                    filter,
                    sort,
                }
            );
            this.items = response.data;
            this.fistItemId = this.items[0].id;
            this.isLoading = false;
        },
        async getSummary() {
            const filter = {
                cog_catalog_item_id: this.cogCatalogItemId,
            };
            this.isLoading = true;
            const { data } = await this.$store.dispatch(
                "CogCatalogItemTransactionSummary/getList",
                {
                    filter,
                }
            );
            this.isLoading = false;
            const { totalQuantity, totalCog } = data;
            const thead = this.$refs.table.$el.querySelector("thead");
            this.addTotalRow(thead, totalCog);
            this.addTableTotalRow(thead, totalQuantity);
        },
        addTotalRow(thead, totalCog) {
            const row = thead.querySelector("#total-row") || thead.insertRow(0);
            row.setAttribute("id", "total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "CurrentQty";
            totalLabelCell.colSpan = 1;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = this.currentQuantity;
            let totalCogCell = rowLength ? row.cells[2] : row.insertCell();
            totalCogCell.innerHTML = totalCog.toFixed(2);
        },
        addTableTotalRow(thead, currentQuantity) {
            const row =
                thead.querySelector("#table-total-row") || thead.insertRow();
            row.setAttribute("id", "table-total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "Total";
            totalLabelCell.colSpan = 1;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = currentQuantity;
            if (rowLength) {
                return;
            }
            row.insertCell();
        },
    },
};
</script>
<style lang="sass">

.transaction-items-table
    table
        thead
            tr#total-row
                td
                    font-weight: bold
</style>
