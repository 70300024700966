<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list
            ref="list"
            :resource="resource.name"
            :filters="filters"
            :disable-create="true"
            :items-per-page="perPage"
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            disable-pagination
            disable-global-search
        >
            <template v-slot:filter.date="props">
                <calendar-filter
                    v-bind="props"
                    @update="(val) => update(val)"
                ></calendar-filter>
            </template>
            <va-data-table
                ref="table"
                :fields="fields"
                disable-actions
                disable-select
                show-expand
            >
                <template v-slot:expanded-item="{ item }">
                    <json-viewer
                        :value="item"
                        :expand-depth="5"
                        copyable
                        sort
                    ></json-viewer>
                </template>
            </va-data-table>
        </va-list>
    </base-material-card>
</template>

<script>
import moment from "moment-timezone";
// import groupBy from "lodash/groupBy";
import DateRanges from "@/constants/DateRanges";
import FinancialEventTypes from "@/constants/FinancialEventTypes";
import CalendarFilter from "@/components/CalendarFilter.vue";

export default {
    props: {
        resource: {
            type: Object,
        },
        title: {
            type: String,
        },
        perPage: {
            type: Number,
            default: 15,
        },
    },
    components: { CalendarFilter },
    mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            (items = []) => {
                console.log(items);
                // items = items.map(item => {
                //     return item;
                // });
                // let groups = [];
                //                 items.forEach(item => {
                //
                //                 groupBy(occurences, function (date) {
                //   return moment(date).startOf('day').format();
                // });
                //                 })

                // this.totalAmount = items
                //     .reduce((previousValue, currentValue) => {
                //         return previousValue + currentValue.amount;
                //     }, 0)
                //     .toFixed(2);
                // const tbody = this.$refs.table.$el.querySelector("tbody");
                // const row =
                //     tbody.querySelector("#total-row") || tbody.insertRow();
                // row.setAttribute("id", "total-row");
                // const rowLength = row.cells.length;
                // let totalLabelCell = rowLength
                //     ? row.cells[0]
                //     : row.insertCell();
                // totalLabelCell.innerHTML = "Total";
                // totalLabelCell.colSpan = 2;
                // let totalValueCell = rowLength
                //     ? row.cells[1]
                //     : row.insertCell();
                // totalValueCell.innerHTML = this.totalAmount;
            }
        );
    },
    beforeMount() {
        this.updateDateColumns(
            moment(DateRanges["This month"][0]),
            moment(DateRanges["This month"][1])
        );
    },
    data() {
        return {
            dateRange: {},
            totalAmount: 0,
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
            },
            sort: {
                by: ["date"],
                desc: [true],
            },
            filters: [
                {
                    source: "entity_type",
                    type: "select",
                    attributes: {
                        choices: FinancialEventTypes,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "amount", type: "text" },
                { source: "date", type: "date", alwaysOn: true },
            ],
            fields: [
                { source: "entity_type", sortable: true },
                { source: "amount", sortable: true },
            ],
        };
    },
    methods: {
        update(value) {
            this.filter = {
                ...this.filter,
                date_between: `${value.formattedStartDate},${value.formattedEndDate}`,
            };
            this.updateDateColumns(value.startDate, value.endDate);
        },

        updateDateColumns(start, end) {
            this.fields = [
                { source: "entity_type", sortable: true },
                { source: "amount", sortable: true },
            ];
            for (let m = start.clone(); m.isBefore(end); m.add(1, "days")) {
                this.fields.push({
                    source: m.format("YYYY-MM-DD"),
                    sortable: false,
                });
            }
        },
    },
};
</script>
<style></style>
