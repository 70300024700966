<template>
    <base-material-card :icon="resource.icon" :title="title">
        <va-list
            :filters="filters"
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
            ref="list"
            disable-global-search
            disable-create
            disable-create-redirect
            disable-export
        >
            <template v-slot:filter.end_date="props">
                <calendar-filter
                    v-bind="props"
                    @update="(val) => update(val)"
                ></calendar-filter>
            </template>
            <va-data-table
                :fields="fields"
                disable-clone
                disable-delete
                disable-select
                disable-actions
            >
                <template v-slot:[`field.end_date`]="{ value }">
                    <span>{{ value.split("T")[0] }}</span>
                </template>
                <template v-slot:[`field.raw`]="{ value, item }">
                    <a v-if="item.raw.length" @click="openReportDialog(item)"
                        >View Report</a
                    >
                </template>
            </va-data-table>
        </va-list>
        <v-dialog v-model="dialog" @click:outside="closeReportDialog">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="closeReportDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="item">
                        Type: {{ item.report_type_id.replace("sp", "") }}, Date:
                        {{ item.end_date.split("T")[0] }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-list v-if="item">
                    <v-list-item>
                        <v-list-item-content>
                            <AdsListReportSimpleList
                                v-if="item.raw.length"
                                :items="item.raw"
                            ></AdsListReportSimpleList>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </base-material-card>
</template>

<script>
import AdsListReportStatuses from "@/constants/AdsListReportStatuses";
import moment from "moment-timezone";
import { tz, sourceTz } from "@/constants/Time";
import AdsReportTypes from "@/constants/AdsListReportTypes";
import CalendarFilter from "@/components/CalendarFilter.vue";

export default {
    props: ["resource", "title"],
    components: { CalendarFilter },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            dateFormat: this.$admin.options.dateFormat,
            item: null,
            dialog: false,
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
            },
            sort: {
                by: ["id"],
                desc: [true],
            },
            filters: [
                {
                    label: "Profile",
                    source: "parent_id",
                    type: "select",
                    attributes: {
                        itemText: "acc_name",
                        multiple: true,
                        reference: "AdsProfile",
                    },
                    alwaysOn: true,
                },
                {
                    source: "report_type_id",
                    type: "select",
                    attributes: {
                        choices: AdsReportTypes,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "status",
                    type: "select",
                    attributes: {
                        choices: AdsListReportStatuses,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { source: "end_date", type: "date", alwaysOn: true },
            ],
            fields: [
                "profile_id",
                "profile_name",
                {
                    source: "report_type_id",
                    type: "select",
                    attributes: { choices: AdsReportTypes },
                    sortable: true,
                },
                {
                    source: "status",
                    type: "select",
                    attributes: { choices: AdsListReportStatuses },
                    sortable: true,
                },
                {
                    label: "Date",
                    source: "end_date",
                    sortable: true,
                },
                {
                    label: "Action",
                    source: "raw",
                },
            ],
        };
    },
    methods: {
        update(value) {
            this.filter = {
                ...this.filter,
                date_between: `${value.formattedStartDate},${value.formattedEndDate}`,
            };
        },
        openReportDialog(item) {
            this.item = item;
            this.dialog = true;
        },
        closeReportDialog() {
            this.dialog = false;
            this.item = false;
        },
    },
};
</script>
