export default [
    {
        name: "users",
        icon: "mdi-account",
        routes: ["list"],
        permissions: ["admin"],
    },
    { name: "Marketplace", api: "marketplaces", label: "id" },
    {
        name: "FinancialEventGroup",
        api: "financial_event_groups",
        label: "id",
    },
    {
        name: "FinancialEvent",
        api: "financial_events",
        label: "id",
        icon: "mdi-calendar-clock",
    },
    { name: "FinancialOrder", api: "financial_orders", label: "id" },
    {
        name: "FinancialOrderDirectPayment",
        api: "financial_order_direct_payments",
        label: "id",
    },
    { name: "AdjustmentEvent", api: "adjustment_events", label: "id" },
    {
        name: "AffordabilityExpenseEvent",
        api: "affordability_expense_events",
        label: "id",
    },
    {
        name: "AffordabilityExpenseReversalEvent",
        api: "a_e_r_events",
        label: "id",
    },
    { name: "ChargebackEvent", api: "chargeback_events", label: "id" },
    {
        name: "CouponPaymentEvent",
        api: "coupon_payment_events",
        label: "id",
    },
    {
        name: "DebtRecoveryEvent",
        api: "debt_recovery_events",
        label: "id",
    },
    {
        name: "FBALiquidationEvent",
        api: "f_b_a_liquidation_events",
        label: "id",
    },
    {
        name: "GuaranteeClaimEvent",
        api: "guarantee_claim_events",
        label: "id",
    },
    {
        name: "ImagingServicesFeeEvent",
        api: "imaging_services_fee_events",
        label: "id",
    },
    {
        name: "LoanServicingEvent",
        api: "loan_servicing_events",
        label: "id",
    },
    {
        name: "NetworkComminglingTransactionEvent",
        api: "n_c_t_events",
        label: "id",
    },
    {
        name: "PayWithAmazonEvent",
        api: "pay_with_amazon_events",
        label: "id",
    },
    {
        name: "ProductAdsPaymentEvent",
        api: "product_ads_payment_events",
        label: "id",
    },
    { name: "RefundEvent", api: "refund_events", label: "id" },
    {
        name: "RentalTransactionEvent",
        api: "rental_transaction_events",
        label: "id",
    },
    { name: "RetrochargeEvent", api: "retrocharge_events", label: "id" },
    {
        name: "SAFETReimbursementEvent",
        api: "s_a_f_e_t_reimbursement_events",
        label: "id",
    },
    {
        name: "SellerDealPaymentEvent",
        api: "seller_deal_payment_events",
        label: "id",
    },
    {
        name: "SellerReviewEnrollmentPaymentEvent",
        api: "s_r_e_p_events",
        label: "id",
    },
    { name: "ServiceFeeEvent", api: "service_fee_events", label: "id" },
    { name: "ShipmentEvent", api: "shipment_events", label: "id" },
    {
        name: "SolutionProviderCreditEvent",
        api: "solution_provider_credit_events",
        label: "id",
    },
    {
        name: "AdjustmentEventItem",
        api: "adjustment_event_items",
        label: "id",
    },
    {
        name: "CouponPaymentEventCharge",
        api: "coupon_payment_event_charges",
        label: "id",
    },
    {
        name: "CouponPaymentEventFee",
        api: "coupon_payment_event_fees",
        label: "id",
    },
    {
        name: "DebtRecoveryEventChargeInstrument",
        api: "d_r_event_charge_instruments",
        label: "id",
    },
    {
        name: "DebtRecoveryEventItem",
        api: "debt_recovery_event_items",
        label: "id",
    },
    {
        name: "ImagingServicesFeeEventFee",
        api: "imaging_services_fee_event_fees",
        label: "id",
    },
    {
        name: "PayWithAmazonEventCharge",
        api: "pay_with_amazon_event_charges",
        label: "id",
    },
    {
        name: "PayWithAmazonEventFee",
        api: "pay_with_amazon_event_fees",
        label: "id",
    },
    {
        name: "RefundEventOrderCharge",
        api: "refund_event_order_charges",
        label: "id",
    },
    {
        name: "RefundEventOrderFee",
        api: "refund_event_order_fees",
        label: "id",
    },
    {
        name: "RefundEventShipmentFee",
        api: "refund_event_shipment_fees",
        label: "id",
    },
    {
        name: "RefundEventShipmentItem",
        api: "refund_event_shipment_items",
        label: "id",
    },
    {
        name: "RentalTransactionEventCharge",
        api: "r_t_event_charges",
        label: "id",
    },
    {
        name: "RentalTransactionEventFee",
        api: "rental_transaction_event_fees",
        label: "id",
    },
    {
        name: "RentalTransactionEventTaxWithheldCharge",
        api: "r_t_event_tax_withheld_charges",
        label: "id",
    },
    {
        name: "RetrochargeEventTaxWithheldCharge",
        api: "r_event_tax_withheld_charges",
        label: "id",
    },
    {
        name: "SAFETReimbursementEventItemCharge",
        api: "s_a_f_e_t_reimbursement_event_item_charges",
        label: "id",
    },
    {
        name: "SellerReviewEnrollmentPaymentEventCharge",
        api: "s_r_e_p_event_charges",
        label: "id",
    },
    {
        name: "SellerReviewEnrollmentPaymentEventFee",
        api: "s_r_e_p_event_fees",
        label: "id",
    },
    {
        name: "ServiceFeeEventFee",
        api: "service_fee_event_fees",
        label: "id",
    },
    {
        name: "ShipmentEventOrderCharge",
        api: "shipment_event_order_charges",
        label: "id",
    },
    {
        name: "ShipmentEventOrderFee",
        api: "shipment_event_order_fees",
        label: "id",
    },
    {
        name: "ShipmentEventShipmentFee",
        api: "shipment_event_shipment_fees",
        label: "id",
    },
    {
        name: "ShipmentEventShipmentItem",
        api: "shipment_event_shipment_items",
        label: "id",
    },
    {
        name: "RefundEventShipmentItemCharge",
        api: "r_event_shipment_item_charges",
        label: "id",
    },
    {
        name: "RefundEventShipmentItemFee",
        api: "refund_event_shipment_item_fees",
        label: "id",
    },
    {
        name: "RefundEventShipmentItemTaxWithheldCharge",
        api: "r_e_s_i_t_w_charges",
        label: "id",
    },
    {
        name: "ShipmentEventShipmentItemCharge",
        api: "s_event_shipment_item_charges",
        label: "id",
    },
    {
        name: "ShipmentEventShipmentItemFee",
        api: "shipment_event_shipment_item_fees",
        label: "id",
    },
    {
        name: "ShipmentEventShipmentItemTaxWithheldCharge",
        api: "s_event_s_i_t_w_charges",
        label: "id",
    },
    {
        name: "FinancialTransaction",
        api: "financial_transactions",
        label: "id",
        icon: "mdi-bank-transfer",
    },
    {
        name: "OperationFee",
        api: "financial_transactions",
        label: "id",
        icon: "mdi-bank-transfer-out",
    },
    {
        name: "FinancialTransactionSummary",
        api: "financial_transaction_summaries",
    },
    {
        name: "FinancialTransactionGroup",
        api: "financial_transaction_groups",
        label: "id",
        icon: "mdi-bank-transfer",
    },
    {
        name: "MwsSynchronization",
        api: "mws_synchronizations",
        icon: "mdi-cloud-sync",
        permissions: ["admin"],
    },
    { name: "Order", api: "orders", icon: "mdi-clipboard-list" },
    {
        name: "OrderForecastItem",
        api: "order_forecast_items",
        icon: "mdi-clipboard-list",
    },
    {
        name: "OrderForecastSummary",
        api: "order_forecast_sum",
        icon: "mdi-clipboard-list",
    },
    { name: "OrderItem", api: "order_items", icon: "mdi-clipboard-list" },
    {
        name: "FbaInventory",
        api: "fba_inventories",
        icon: "mdi-checkbox-multiple-marked",
    },
    {
        name: "SecondFbaInventory",
        api: "fba_inventories",
        icon: "mdi-checkbox-multiple-marked",
    },
    { name: "FbaInventorySummaries", api: "fba_inventory_summaries" },
    {
        name: "FbaInboundShipment",
        api: "fba_inbound_shipments",
        icon: "mdi-clipboard-list",
    },
    {
        label: "name",
        name: "MwsConfiguration",
        api: "mws_configurations",
        icon: "mdi-cog",
        permissions: ["admin"],
    },
    {
        name: "CatalogItem",
        label: "SellerSKU",
        api: "catalog_items",
        icon: "mdi-webpack",
    },
    {
        name: "CogCatalogItem",
        api: "cog_catalog_items",
        icon: "mdi-cash-multiple",
    },
    {
        name: "SecondCogCatalogItem",
        api: "cog_catalog_items",
        icon: "mdi-cash-multiple",
    },
    {
        name: "CogCatalogItemTransaction",
        api: "cog_catalog_item_transactions",
        icon: "mdi-arrow-split-vertical",
    },
    {
        name: "SaleInventory",
        api: "sale_inventories",
        icon: "mdi-google-analytics",
    },
    {
        name: "AsinProfit",
        api: "asin_profits",
        icon: "mdi-google-analytics",
    },
    {
        name: "SecondSaleInventory",
        api: "sale_inventories",
        icon: "mdi-google-analytics",
    },
    {
        name: "SaleInventoryNewAsin",
        api: "sale_inventories",
        icon: "mdi-google-analytics",
    },
    {
        name: "SaleInventoryReport",
        api: "sale_inventory_reports",
        icon: "mdi-google-analytics",
    },
    {
        name: "UsWarehouseInventory",
        api: "us_warehouse_inventories",
        icon: "mdi-checkbox-multiple-marked",
    },
    {
        name: "Supplier",
        api: "suppliers",
        icon: "mdi-truck",
        label: "id",
    },
    { name: "SupplierMap", api: "supplier_map" },
    {
        name: "Report",
        api: "reports",
        icon: "mdi-google-analytics",
        permissions: ["admin"],
    },
    { name: "CogCatalogItemSummary", api: "cog_catalog_item_summaries" },
    { name: "CogCatalogItemMap", api: "cog_catalog_item_map" },
    {
        name: "CogCatalogItemTransactionSummary",
        api: "cog_catalog_item_trans_sum",
    },
    {
        name: "OrderRequest",
        api: "order_requests",
        icon: "mdi-table-arrow-right",
    },
    {
        name: "OrderRequestItem",
        api: "order_request_items",
    },
    {
        name: "OrderRequestExport",
        api: "export_order_requests",
    },
    {
        label: "name",
        name: "AdsConfiguration",
        api: "ads_configurations",
        icon: "mdi-cog",
        permissions: ["admin"],
    },
    {
        label: "name",
        name: "AdsListReport",
        api: "ads_list_reports",
        icon: "mdi-advertisements",
        permissions: ["admin"],
    },
    {
        name: "AdsProfile",
        api: "ads_profiles",
        permissions: ["admin"],
    },
    {
        name: "AsinProfileReport",
        api: "asin_profile_reports",
    },
    {
        name: "AsinProfile",
        api: "cog_catalog_items",
        icon: "mdi-google-analytics",
    },
];
