<template>
    <div>
        <va-aside-layout :title="asideTitle">
            <operation-fee-form
                v-if="show"
                :id="id"
                :item="item"
                :mwsConfigurationId="filter.mws_configuration_id"
                @saved="onSaved"
            ></operation-fee-form>
        </va-aside-layout>
        <base-material-card :icon="resource.icon" :title="title">
            <v-tabs v-model="selectedTab">
                <v-tab
                    class="mr-3"
                    v-for="tab in mwsConfigurationOptions"
                    :key="tab.value"
                    @click="onClickTab(tab.value)"
                >
                    <v-icon class="mr-2">mdi-amazon</v-icon>
                    {{ tab.text }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab" class="transparent">
            </v-tabs-items>
            <va-list
                ref="list"
                class="operation-fee-list"
                :resource="resource.name"
                :filters="filters"
                :items-per-page="15"
                :filter.sync="filter"
                :sort-by.sync="sort.by"
                :sort-desc.sync="sort.desc"
                disable-create-redirect
                disable-export
                @action="onAction"
            >
                <template v-slot:filter.date="props">
                    <calendar-filter
                        v-bind="props"
                        @update="(val) => update(val)"
                    ></calendar-filter>
                </template>
                <va-data-table
                    ref="table"
                    :fields="fields"
                    class="main-table"
                    disable-clone
                    disable-show
                    disable-select
                    disable-edit-redirect
                    @item-action="onAction"
                    show-expand
                >
                    <template v-slot:[`field.date`]="{ value }">
                        <span>{{
                            value | moment("timezone", sourceTz, "LLL")
                        }}</span>
                    </template>
                    <template v-slot:[`expanded-item`]="{ item }">
                        <v-data-table
                            v-if="item.children && item.children.length"
                            hide-default-header
                            hide-default-footer
                            class="transition-headers"
                            :items-per-page="100"
                            :headers="transition_headers"
                            :items="item.children"
                        ></v-data-table>
                    </template>
                </va-data-table>
            </va-list>
        </base-material-card>
    </div>
</template>

<script>
import moment from "moment-timezone";
import { tz, sourceTz } from "@/constants/Time";
import CalendarFilter from "@/components/CalendarFilter.vue";

export default {
    props: ["resource", "title"],
    components: { CalendarFilter },
    computed: {
        mwsConfigurationOptions() {
            return this.$store.getters.mwsConfigurationOptions;
        },
    },
    watch: {
        mwsConfigurationOptions: {
            immediate: true,
            handler(newVal) {
                if (!newVal || !newVal.length) {
                    return;
                }
                const filter = this.filters.find(
                    (filter) => filter.source === "mws_configuration_id"
                );
                filter.attributes.choices = newVal;
            },
        },
    },
    beforeMount() {
        const { filter } = this.$route.query;
        if (!filter) {
            this.dateRange.startDate = moment().tz(tz).startOf("month");
            this.dateRange.endDate = moment().tz(tz).endOf("month");
            return;
        }
        const currentFilter = JSON.parse(filter);
        if (!currentFilter["date_between"]) {
            return;
        }
        const [start, end] = currentFilter["date_between"].split(",");
        this.dateRange.startDate = moment(start);
        this.dateRange.endDate = moment(end);
    },
    async mounted() {
        this.$watch(
            () => {
                return this.$refs.list.listState.items;
            },
            () => {
                this.updateSummary();
            }
        );
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            selectedTab: 0,
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD 00:00:00")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD 23:59:59")}`,
                entity_type: "OperationFee",
            },
            sort: {
                by: ["date"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                {
                    source: "entity_type",
                    type: "text",
                    alwaysOn: true,
                },
                { source: "date", type: "date", alwaysOn: true },
                { source: "SellerSKU", type: "text", alwaysOn: true },
                { source: "ASIN", type: "text", alwaysOn: true },
                // { source: "quantity", type: "text" },
                // { source: "amount", type: "text" },
            ],
            fields: [
                { source: "entity_type", sortable: true },
                { source: "SellerSKU", sortable: true },
                { source: "ASIN", sortable: true },
                { source: "date", sortable: true },
                { source: "quantity", sortable: true },
                { source: "amount", sortable: true },
            ],
            transition_headers: [
                {
                    value: "transaction_type",
                    sortable: true,
                },
                { value: "SellerSKU", sortable: true },
                { value: "ASIN", sortable: true },
                { value: "none", sortable: true },
                { value: "quantity", sortable: true },
                { value: "amount", sortable: true },
                { value: "actions", sortable: false },
            ],
            asideTitle: null,
            id: null,
            item: null,
            show: false,
            mwsConfigurationMap: {},
        };
    },
    methods: {
        update(value) {
            this.filter = {
                ...this.filter,
                date_between: `${value.formattedStartDate},${value.formattedEndDate}`,
            };
        },
        async updateSummary() {
            const { data } = await this.$store.dispatch(
                "FinancialTransactionSummary/getList",
                {
                    filter: this.filter,
                }
            );
            const { amount, quantity } = data;
            const thead = this.$refs.table.$el.querySelector("thead");
            const row = thead.querySelector("#total-row") || thead.insertRow();
            row.setAttribute("id", "total-row");
            const rowLength = row.cells.length;
            let totalLabelCell = rowLength ? row.cells[0] : row.insertCell();
            totalLabelCell.innerHTML = "Total";
            totalLabelCell.colSpan = 5;
            let totalQtyCell = rowLength ? row.cells[1] : row.insertCell();
            totalQtyCell.innerHTML = quantity;
            let totalValueCell = rowLength ? row.cells[2] : row.insertCell();
            totalValueCell.innerHTML = amount.toFixed(2);
        },
        onClickTab(id) {
            this.filter["mws_configuration_id"] = id ? id : "";
            if (!this.show) {
                return;
            }
            this.show = false;
            // setTimeout(() => {
            //     this.show = true;
            // }, 100);
        },
        findSelectedTab(tabs) {
            const { filter } = this.$route.query;
            if (!filter) {
                return;
            }
            const currentFilter = JSON.parse(filter);
            if (!currentFilter["mws_configuration_id"]) {
                return;
            }
            this.selectedTab = parseInt(
                currentFilter["mws_configuration_id"],
                10
            );
            if (!this.selectedTab) {
                return;
            }
            this.selectedTab = tabs.findIndex(
                (item) => item.value === this.selectedTab
            );
        },
        async onAction({ action, title, id, item }) {
            this.asideTitle = title;
            this.id = id;
            this.show = ["create", "edit"].includes(action);
            this.item = item;
        },
        onSaved() {
            this.asideTitle = null;
            this.$refs.list.fetchData();
        },
    },
};
</script>
<style lang="sass">
$filter-height: 120px
$arrow-width: 52px
$number-width: 11%
$number-align: right
$date-width: 12%
$sku-width: 15%
#app
    main
        div.container.flex.container--fluid
            > div
                > div
                    margin-top: 0
                div.d-flex.grow.flex-wrap
                    display: none !important
                div.v-data-iterator.operation-fee-list
                    > header
                        height: $filter-height !important
                        > div
                            height: $filter-height !important
                            > form
                                > div
                                    > div:nth-child(2), div:nth-child(3)
                                        display: none !important
                    .v-data-table__expanded.v-data-table__expanded__content
                        > td
                            padding-left: 0 !important
                            padding-right: 0 !important
                    .main-table
                        > .v-data-table
                            > .v-data-table__wrapper
                                > table
                                    thead
                                        > tr#total-row
                                            > td:nth-child(2), > td:nth-child(3), > td:nth-child(4), > td:nth-child(5)
                                                width: $number-width !important
                                                text-align: $number-align !important
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                width: $arrow-width
                                            > td:nth-child(3), td:nth-child(4)
                                                text-align: left !important
                                                width: $sku-width !important
                                            > td:nth-child(5)
                                                width: $date-width
                                            > td:nth-child(6), td:nth-child(7), > td:nth-child(8) , > td:nth-child(9)
                                                width: $number-width !important
                                                text-align: $number-align !important
                        .transition-headers
                             > .v-data-table__wrapper
                                > table
                                    > tbody
                                        > tr
                                            > td:nth-child(1)
                                                padding-left: 100px
                                            > td:nth-child(2), td:nth-child(3)
                                                width: $sku-width
                                            > td:nth-child(4)
                                                width: $date-width !important
                                            > td:nth-child(5), td:nth-child(6), td:nth-child(7)
                                                width: $number-width !important
                                                text-align: $number-align !important
    .v-navigation-drawer--right
        .va-array-input
            .item
                margin-bottom: 10px
                padding-bottom: 10px
</style>
