var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-material-card',{attrs:{"icon":_vm.resource.icon,"title":_vm.title}},[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.mwsConfigurationOptions),function(tab){return _c('v-tab',{key:tab.value,staticClass:"mr-3",on:{"click":function($event){return _vm.onClickTab(tab.value)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-amazon")]),_vm._v(" "+_vm._s(tab.text)+" ")],1)}),1),_c('v-tabs-items',{staticClass:"transparent",model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}}),_c('va-list',{ref:"list",staticClass:"asin-profile-list",attrs:{"filters":_vm.filters,"filter":_vm.filter,"disable-export":"","disable-create":"","items-per-page":100,"items-per-page-options":[100, 200, 300, 500]},on:{"update:filter":function($event){_vm.filter=$event}}},[_c('va-data-table',{ref:"table",staticClass:"main-table",attrs:{"fields":_vm.fields,"disable-clone":"","disable-select":"","disable-delete":"","disable-show":"","disable-edit-redirect":"","disable-actions":""},scopedSlots:_vm._u([{key:"field.AttributeSets[0]",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value && value.SmallImage)?_c('img',{attrs:{"width":"75","alt":value.SmallImage.URL,"src":_vm.getImage(item, value.SmallImage.URL)}}):_vm._e()]}},{key:"field.ASIN",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.openAsinProfileReport(item)}}},[_vm._v(_vm._s(value))])]}},{key:"field.label",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(value)?_c('v-chip',{attrs:{"text-color":"white","color":_vm.getLabelColor(value)}},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e(),_vm._v(" - ("+_vm._s(parseInt(item.sum_sale_30_day, 10))+") ")]}},{key:"field.tags",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [(item.is_low_price)?_c('v-chip',[_vm._v(" "+_vm._s("LowPrice")+" ")]):_vm._e(),(item.is_neg_gp)?_c('v-chip',[_vm._v(" "+_vm._s("NegGP")+" ")]):_vm._e()]}},{key:"field.created_at",fn:function(ref){
var value = ref.value;
var item = ref.item;
return [_c('v-chip',{attrs:{"text-color":"white","color":_vm.getUploadStatusColor(item)}},[_vm._v(" "+_vm._s(_vm._f("moment")(value,"timezone", _vm.sourceTz, "LLL"))+" ")]),_vm._v(" - "+_vm._s(item.asin_age)+" ")]}}],null,true)})],1),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(" ASIN: "),(_vm.selected)?_c('a',{attrs:{"target":"_blank","href":'https://amazon.com/dp/' + _vm.selected.ASIN}},[_vm._v(_vm._s(_vm.selected.ASIN))]):_vm._e()])],1),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[(!_vm.report)?_c('v-card-text',[_vm._v(" Loading... "),_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('calendar-filter',{attrs:{"custom-ranges":_vm.dateRanges},on:{"update":function (val) { return _vm.applyAsinProfileReportFilter(
                                                val
                                            ); }}}),_c('div',{staticClass:"my-4"},[(_vm.report)?_c('highcharts',{key:"sale-chart",attrs:{"id":"sale-chart","options":_vm.chartOptions}}):_vm._e()],1),_c('div',{staticClass:"my-4"},[(_vm.report)?_c('highcharts',{key:"rank-chart",attrs:{"id":"rank-chart","options":_vm.rankChartOptions}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }