import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mwsConfigurationOptions: [{ text: "All", value: 0 }],
        suppliers: [],
        users: [],
    },
    getters: {
        mwsConfigurationOptions(state) {
            return state.mwsConfigurationOptions;
        },
        suppliers(state) {
            return state.suppliers;
        },
        users(state) {
            return state.users;
        },
        "auth/getMwsConfigurationIds"(state) {
            return state.auth.user.mws_configuration_ids;
        },
    },
    mutations: {
        mwsConfigurationOptions(state, payload) {
            state.mwsConfigurationOptions = [
                { text: "All", value: 0 },
                ...payload.mwsConfigurationOptions,
            ];
            return state.mwsConfigurationOptions;
        },
        suppliers(state, payload) {
            state.suppliers = [...payload.suppliers];
            return state.suppliers;
        },
        users(state, payload) {
            state.users = [...payload.users];
            return state.users;
        },
    },
    actions: {
        setMwsConfigurationOptions(context, payload) {
            context.commit("mwsConfigurationOptions", payload);
        },
        setSuppliers(context, payload) {
            context.commit("suppliers", payload);
        },
        setUsers(context, payload) {
            context.commit("users", payload);
        },
    },
    modules: {},
});
