<template>
    <base-material-card :icon="resource.icon" :title="title">
        <v-tabs v-model="selectedTab">
            <v-tab
                class="mr-3"
                v-for="tab in mwsConfigurationOptions"
                :key="tab.value"
                @click="onClickTab(tab.value)"
            >
                <v-icon class="mr-2">mdi-amazon</v-icon>
                {{ tab.text }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="selectedTab" class="transparent"></v-tabs-items>
        <va-list
            :filters="filters"
            ref="list"
            class="sale-inventory-list-new-asin"
            disable-create
            disable-export
            :filter.sync="filter"
            :sort-by.sync="sort.by"
            :sort-desc.sync="sort.desc"
        >
            <template v-slot:actions>
                <va-action-button
                    label="Export"
                    icon="mdi-download"
                    text
                    :loading="isGenerating"
                    @click="exportAll"
                ></va-action-button>
                <a
                    :href="fileLink"
                    ref="fileLinkElement"
                    class="d-none"
                    download="sale-inventory.csv"
                    >Download exported file.</a
                >
            </template>
            <template v-slot:filter.fba_inventory_create_date="props">
                <calendar-filter
                    v-bind="props"
                    :custom-ranges="dateRanges"
                    @update="(val) => updateDateFilter(val)"
                ></calendar-filter>
            </template>
            <va-data-table
                ref="table"
                :fields="fields"
                class="sale-inventory-list-new-asin-main-table"
                disable-edit
                disable-clone
                disable-show
                disable-select
                disable-delete
                show-expand
                single-expand
            >
                <template v-slot:[`field.asin`]="{ value, item }">
                    <a @click="openAsinReport(item)">{{ value }}</a>
                </template>
                <template v-slot:[`field.sku`]="{ value }">
                    <div class="col-sku">
                        {{ value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.fnSku`]="{ value }">
                    <div class="col-fn-sku">
                        {{ value && value.includes(",") ? "Multiple" : value }}
                    </div>
                </template>
                <template v-slot:[`field.pics`]="{ value }">
                    <div class="col-pics">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.suppliers`]="{ value }">
                    <div class="col-suppliers">
                        {{ value ? value.map((i) => i.name).join(", ") : "" }}
                    </div>
                </template>
                <template v-slot:[`field.sum_sale_1_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_1_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_1_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_3_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_3_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_3_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_7_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span :class="getDiffCssClass(item, 'diff_sum_sale_7_day')">
                        {{ getDiffLabel(item, "diff_sum_sale_7_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_15_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span
                        :class="getDiffCssClass(item, 'diff_sum_sale_15_day')"
                    >
                        {{ getDiffLabel(item, "diff_sum_sale_15_day") }}
                    </span>
                </template>
                <template v-slot:[`field.sum_sale_30_day`]="{ value, item }">
                    {{ value }}
                    <br />
                    <span
                        :class="getDiffCssClass(item, 'diff_sum_sale_30_day')"
                    >
                        {{ getDiffLabel(item, "diff_sum_sale_30_day") }}
                    </span>
                </template>
                <template v-slot:[`field.label`]="{ value }">
                    <v-chip text-color="white" :color="getLabelColor(value)">
                        {{ value }}
                    </v-chip>
                </template>
                <template
                    v-slot:[`field.fba_inventory_create_date`]="{ value }"
                >
                    <span>{{
                        value | moment("timezone", sourceTz, "LL")
                    }}</span>
                </template>
                <template v-slot:expanded-item="{ item }">
                    <v-data-table
                        v-if="item.children.length"
                        hide-default-header
                        hide-default-footer
                        class="sale-inventory-list-new-asin-item-headers"
                        :headers="item_headers"
                        :items="item.children"
                    >
                        <template v-slot:item.first="{}">
                            <button
                                type="button"
                                class="
                                    v-icon
                                    v-data-table__expand-icon
                                    v-icon--link
                                    mdi mdi-square-small
                                "
                            ></button>
                        </template>
                        <template v-slot:item.small_image="{}"> </template>
                        <template v-slot:item.sku="{ value }">
                            <div class="col-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.fnSku="{ value }">
                            <div class="col-fn-sku">
                                {{ value }}
                            </div>
                        </template>
                        <template v-slot:item.pics="{ value }">
                            <div class="col-pics">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.suppliers="{ value }">
                            <div class="col-suppliers">
                                {{
                                    value
                                        ? value.map((i) => i.name).join(", ")
                                        : ""
                                }}
                            </div>
                        </template>
                        <template v-slot:item.sum_sale_1_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_1_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_1_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_3_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_3_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_3_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_7_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(item, 'diff_sum_sale_7_day')
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_7_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_15_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(
                                        item,
                                        'diff_sum_sale_15_day'
                                    )
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_15_day") }}
                            </span>
                        </template>
                        <template v-slot:item.sum_sale_30_day="{ value, item }">
                            {{ value }}
                            <br />
                            <span
                                :class="
                                    getDiffCssClass(
                                        item,
                                        'diff_sum_sale_30_day'
                                    )
                                "
                            >
                                {{ getDiffLabel(item, "diff_sum_sale_30_day") }}
                            </span>
                        </template>
                        <template v-slot:item.label="{ value }">
                            <v-chip
                                text-color="white"
                                :color="getLabelColor(value)"
                            >
                                {{ value }}
                            </v-chip>
                        </template>
                        <template
                            v-slot:item.fba_inventory_create_date="{ value }"
                        >
                            <span>{{
                                value | moment("timezone", sourceTz, "LL")
                            }}</span>
                        </template>
                    </v-data-table>
                </template>
            </va-data-table>
        </va-list>
        <v-dialog v-model="dialog">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        ASIN:
                        <a
                            v-if="selected"
                            target="_blank"
                            :href="'https://amazon.com/dp/' + selected.asin"
                            >{{ selected.asin }}</a
                        >
                    </v-toolbar-title>
                </v-toolbar>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-card-text v-if="!report">
                                Loading...
                                <v-progress-linear
                                    indeterminate
                                ></v-progress-linear>
                            </v-card-text>
                            <calendar-filter
                                :custom-ranges="dateRanges"
                                @update="(val) => applyAsinReportFilter(val)"
                            ></calendar-filter>
                            <highcharts
                                v-if="report"
                                :options="chartOptions"
                            ></highcharts>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-dialog>
    </base-material-card>
</template>

<script>
import Highcharts from "highcharts";
import { Chart } from "highcharts-vue";
import { tz, sourceTz } from "@/constants/Time";
import dateRanges from "@/constants/NewAsinDateRanges";
import MarketplaceIds from "@/constants/MarketplaceIds";
import List from "@/resources/sale-inventory/List";
import SaleInventoryLabels from "@/constants/SaleInventoryLabels";
import moment from "moment-timezone";
import CalendarFilter from "@/components/CalendarFilter.vue";

export default {
    mixins: [List],
    props: ["resource", "title"],
    components: {
        highcharts: Chart,
        CalendarFilter,
    },
    data() {
        return {
            tz: tz,
            sourceTz: sourceTz,
            selectedTab: 0,
            isGenerating: false,
            marketplaceIdKey: "main_marketplace_id",
            filter: {
                date_between: `${moment()
                    .startOf("month")
                    .format("YYYY-MM-DD")},${moment()
                    .endOf("month")
                    .format("YYYY-MM-DD")}`,
                second_marketplace: 0,
            },
            sort: {
                by: ["sum_sale_30_day"],
                desc: [true],
            },
            filters: [
                {
                    label: "App Configuration",
                    source: "mws_configuration_id",
                    type: "select",
                    attributes: {
                        choices: this.mwsConfigurationOptions,
                        multiple: true,
                    },
                    alwaysOn: true,
                },
                { label: "ASIN", source: "asin", type: "text", alwaysOn: true },
                { label: "SKU", source: "sku", type: "text", alwaysOn: true },
                {
                    label: "FnSKU",
                    source: "fnSku",
                    type: "text",
                    alwaysOn: true,
                },
                {
                    label: "Supplier",
                    source: "supplier_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "Supplier",
                    },
                    alwaysOn: true,
                },
                {
                    label: "PIC",
                    source: "pic_ids",
                    type: "select",
                    attributes: {
                        itemText: "name",
                        multiple: true,
                        reference: "users",
                    },
                    alwaysOn: true,
                },
                {
                    source: "marketplace_id",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: MarketplaceIds,
                    },
                    alwaysOn: true,
                },
                {
                    source: "label",
                    type: "select",
                    attributes: {
                        multiple: true,
                        choices: SaleInventoryLabels,
                    },
                    alwaysOn: true,
                },
                {
                    source: "fba_inventory_create_date",
                    type: "date",
                    alwaysOn: true,
                },
            ],
            fields: [
                {
                    label: "Date Create",
                    source: "fba_inventory_create_date",
                    sortable: true,
                },
                {
                    label: "Image",
                    source: "small_image",
                    attributes: {
                        src: "URL",
                    },
                    type: "image",
                    sortable: false,
                },
                { label: "ASIN", source: "asin", sortable: true },
                { label: "Label", source: "label", sortable: false },
                { label: "SKU", source: "sku", sortable: true },
                { label: "FnSKU", source: "fnSku", sortable: true },
                {
                    label: "Supplier",
                    source: "suppliers",
                    sortable: false,
                },
                {
                    label: "PIC",
                    source: "pics",
                    sortable: false,
                },
                {
                    label: "Yesterday",
                    source: "sum_sale_1_day",
                    sortable: true,
                },
                {
                    label: "Last 3 days",
                    source: "sum_sale_3_day",
                    sortable: true,
                },
                {
                    label: "Last 7 days",
                    source: "sum_sale_7_day",
                    sortable: true,
                },
                {
                    label: "Last 15 days",
                    source: "sum_sale_15_day",
                    sortable: true,
                },
                {
                    label: "Last 30 days",
                    source: "sum_sale_30_day",
                    sortable: true,
                },
                {
                    label: "Average per day (EMA)",
                    source: "ema",
                    sortable: true,
                },
                {
                    label: "Average per day (SMA)",
                    source: "sma",
                    sortable: true,
                },
            ],
            item_headers: [
                { text: "first", value: "first", sortable: false },
                {
                    text: "Date Create",
                    value: "fba_inventory_create_date",
                    sortable: true,
                },
                { text: "small_image", value: "small_image", sortable: false },
                { text: "asin", value: "asin", sortable: false },
                { text: "label", value: "label", sortable: false },
                { text: "SKU", value: "sku", sortable: true },
                { text: "FnSKU", value: "fnSku", sortable: true },
                { text: "Supplier", value: "suppliers", sortable: true },
                { text: "PIC", value: "pics", sortable: true },
                { text: "Yesterday", value: "sum_sale_1_day", sortable: true },
                { text: "3 days", value: "sum_sale_3_day", sortable: true },
                { text: "7 days", value: "sum_sale_7_day", sortable: true },
                { text: "15 days", value: "sum_sale_15_day", sortable: true },
                { text: "30 days", value: "sum_sale_30_day", sortable: true },
                {
                    text: "ema",
                    value: "ema",
                    sortable: true,
                },
                {
                    text: "sma",
                    value: "sma",
                    sortable: true,
                },
            ],
            isGroupedHeader: false,
            dialog: false,
            selected: false,
            report: false,
            cachedReport: {},
            dateRanges,
            chartOptions: {
                credits: {
                    enabled: false,
                },
                chart: {
                    zoomType: "xy",
                },
                title: {
                    text: "",
                },
                subtitle: {
                    text: "",
                },
                xAxis: [
                    {
                        categories: [],
                        crosshair: true,
                    },
                ],
                yAxis: [
                    {
                        // Primary yAxis
                        labels: {
                            format: "{value} items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                        title: {
                            text: "Items",
                            style: {
                                color: Highcharts.getOptions().colors[1],
                            },
                        },
                    },
                    {
                        // Secondary yAxis
                        title: {
                            text: "Rev",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        labels: {
                            format: "{value}$",
                            style: {
                                color: Highcharts.getOptions().colors[0],
                            },
                        },
                        opposite: true,
                    },
                ],
                tooltip: {
                    shared: true,
                },
                legend: {
                    layout: "vertical",
                    align: "left",
                    x: 120,
                    verticalAlign: "top",
                    y: 100,
                    floating: true,
                    backgroundColor:
                        Highcharts.defaultOptions.legend.backgroundColor || // theme
                        "rgba(255,255,255,0.25)",
                },
                series: [
                    {
                        name: "Rev",
                        type: "column",
                        yAxis: 1,
                        data: [],
                        tooltip: {
                            valueSuffix: "$",
                        },
                    },
                    {
                        name: "Items",
                        type: "spline",
                        data: [],
                        tooltip: {
                            valueSuffix: " items",
                        },
                    },
                ],
            },
        };
    },
    methods: {
        buildHeader(newValue) {
            if (!newValue.length) {
                this.isGroupedHeader = false;
                return;
            }
            if (this.isGroupedHeader) {
                return;
            }
            const table = this.$refs.table.$el.querySelector("table");
            const defaultColGroup = table.querySelector("colgroup");
            defaultColGroup.span = 3;
            defaultColGroup.childNodes.forEach((child, index) => {
                if (index < 3) {
                    return;
                }
                child.remove();
            });
            const col1 = document.createElement("col");
            table.insertBefore(col1, defaultColGroup);
            // fba_create_date,image,asin,label,sku,fnSku,pic,supplier
            const colGroup1 = document.createElement("colgroup");
            colGroup1.span = 8;
            colGroup1.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup1, defaultColGroup);
            // Sale
            const colGroup2 = document.createElement("colgroup");
            colGroup2.span = 7;
            colGroup2.append(
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col"),
                document.createElement("col")
            );
            table.insertBefore(colGroup2, defaultColGroup);
            //
            const thead = table.tHead;
            const defaultHeadRow = thead.rows[0];
            defaultHeadRow.cells[0].remove();
            const newHeadRow = thead.insertRow(0);
            const newHeadRowCell1 = newHeadRow.insertCell(0);
            newHeadRowCell1.rowSpan = 2;
            const newHeadRowCell2 = newHeadRow.insertCell(1);
            newHeadRowCell2.scope = "colgroup";
            newHeadRowCell2.colSpan = 8;
            const newHeadRowCell3 = newHeadRow.insertCell(2);
            newHeadRowCell3.scope = "colgroup";
            newHeadRowCell3.className = "text-center";
            newHeadRowCell3.colSpan = 7;
            newHeadRowCell3.innerText = "Sales (unit)";
            this.isGroupedHeader = true;
        },
        updateDateFilter(value) {
            this.filter = {
                ...this.filter,
                date_between: `${value.startDate
                    .clone()
                    .format("YYYY-MM-DD")},${value.endDate
                    .clone()
                    .format("YYYY-MM-DD")}`,
            };
        },
    },
};
</script>
<style lang="sass">
$filter-height: 80px
$number-col-width: 80px
$image-col-width: 108px
$sale-color: antiquewhite
$sale-color-ma: moccasin
$inventory-color: aliceblue
$us-inventory-color: floralwhite
$stock-remaining-color: powderblue

.menuable__content__active
    z-index: 9999 !important

.sale-inventory-list-new-asin
    > header
        height: $filter-height !important
        z-index: 9999 !important
        > div
            height: $filter-height !important

            > form
                > div
                    > div:nth-child(2)
                        display: none !important

    .v-data-table-header
        tr
            th:nth-child(n+8):nth-child(-n+18)
                width: $number-col-width !important
                min-width: $number-col-width !important

    > form
        > div
            > div
                height: 65vh
                overflow-y: auto

                > table
                    > thead
                        background: white

                        td:nth-child(3),
                        th:nth-child(9), th:nth-child(10), th:nth-child(11), th:nth-child(12), th:nth-child(13)
                            background: $sale-color

                        th:nth-child(14), th:nth-child(15)
                            background: $sale-color-ma

                    > colgroup:nth-child(3)
                        background: $sale-color

                    > tbody
                        > tr
                            > td
                                button
                                    background: transparent !important

                            td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5), td:nth-child(6),
                            td:nth-child(7), td:nth-child(8), td:nth-child(9)
                                width: $image-col-width
                                min-width: $image-col-width
                                max-width: $image-col-width

                            td
                                .col-pics, .col-suppliers
                                    min-width: 60px !important

                            td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13),
                            td:nth-child(14), td:nth-child(15), td:nth-child(16), td:nth-child(17), td:nth-child(18),
                            td:nth-child(19), td:nth-child(20)
                                text-align: right !important

                            td:last-child
                                //width: 85px !important

                                label
                                    display: none !important

                            td:nth-child(15), td:nth-child(16)
                                background: $sale-color-ma

                            table
                                td:nth-child(9), td:nth-child(10), td:nth-child(11), td:nth-child(12), td:nth-child(13)
                                    background: $sale-color

                                td:nth-child(15), td:nth-child(16)
                                    background: $sale-color-ma

                                td:nth-child(n+8):nth-child(-n+20)
                                    width: $number-col-width !important
                                    min-width: $number-col-width !important

.v-toolbar__title a
    color: white !important
</style>
